import { useNuxtApp, useRuntimeConfig } from '#imports';

export const useTracker = () => {
  const { $jitsuTrack, $gtag, ssrContext } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();

  return {
    /** @TODO deprecate in favor of using gtag */
    ga(eventCategory: string, eventAction: string, eventLabel: string) {
      if (ssrContext) {
        return;
      }

      $gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        send_to: runtimeConfig.public.gaId,
      });
    },
    gtagEvent(eventName: string, params?: { [key: string]: string | number | any[] }) {
      if (ssrContext) {
        return;
      }

      $gtag('event', eventName, params);
    },
    jitsuEvent(eventName: string, params?: { [key: string]: string | number | object }) {
      if (ssrContext) {
        return;
      }

      $jitsuTrack(eventName, params);
    },
  };
};
